








































































































































































































































































































































































































































































































































































































































































































































































































































































































.framePage-halfBody {
  display: flex;
  flex: 1;
  overflow: hidden;
}
.framePage-halfBody .framePage-article {
  background-color: #fff;
  margin-left: 0.675rem;
  height: 100%;
  padding: 1rem 0.675rem;
  box-sizing: border-box;
  width: 25%;
  min-width: 20rem;
  .title {
    display: flex;
    justify-content: space-between;
    position: relative;
    h3 {
      font-size: 0.85rem;
    }
    h3:before {
      content: "";
      border-left: 4px solid #4574f9;
      width: 4px;
      height: 16px;
      position: absolute;
      left: -14px;
    }
    .title-button {
      padding: 0;
      font-size: 0.8rem;
    }
  }
  .searchBox {
    display: flex;
    margin: 15px 0;
  }
  .searchqusetionBox {
    display: flex;
    flex-wrap: wrap;
    div {
      width: 50%;
      display: flex;
      margin: 15px 0;
      span {
        display: flex;
        align-items: center;
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
      }
      .addclass {
        color: #4574f9;
      }
    }
  }
  .titleMation {
    display: flex;
    flex-direction: column;
    padding: 0.9rem 0 1.8rem 0;
    span:nth-child(2) {
      padding: 0.5rem 0;
    }
  }
  .btnBox {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
}
.list {
  .list-item {
    background-color: #fafafa;
    padding: 1rem 0;
    margin-bottom: 0.5rem;
    .subject {
      margin-left: 1rem;
      margin-right: 1.5rem;
      .subject-title {
        display: flex;
        justify-content: space-between;
        .number {
          padding-left: 1rem;
        }
        img {
          width: 100px;
        }
      }
      .option {
        display: flex;
        padding: 0.75rem;
        justify-content: space-between;
        span {
          line-height: 2rem;
          width: auto;
          display: flex;
          align-items: center;
          .qbimg {
            width: 5rem;
          }
        }
      }
    }
  }
}
.img-boxs {
  img {
    width: 100px;
  }
}
.tixing {
  font-size: 14px;
  height: 30px;
  padding: 0.25rem;
  border-radius: 0.2rem;
}
.tixing1 {
  color: #37bf77;
  background-color: #d9efe4;
}
.tixing2 {
  color: #fea317;
  background-color: #f9efd2;
}
.tixing3 {
  color: #4574f9;
  background-color: #f1f7ff;
}
.tixing4 {
  color: #f861d8;
  background-color: #fff2fe;
}
.informationmBox {
  display: flex;
  justify-content: space-between;
}
.el-divider--horizontal /deep/ {
  margin: 12px 0;
}
.el-cascader /deep/ {
  width: 100%;
}
.el-form-item__content /deep/ .el-upload {
  width: 100px;
  height: 148px !important;
}
.bannerBox .el-form-item__content /deep/ .df {
  display: flex;
}
.bannerBox .el-form-item__content .hide /deep/.el-upload--picture-card {
  display: none;
}
.el-upload-list--picture-card /deep/.el-upload-list__item-actions {
  z-index: 9999;
}
.showbtn {
  display: none;
}
.hoverShow :hover {
  .showbtn {
    display: block;
  }
}
.liveBroadcastTitle {
  display: flex;
  li {
    flex: 1;
    text-align: center;
  }
}
.pztt {
  .times {
    margin-bottom: 20px;
  }
  .shell {
    border-bottom: 1px solid #1b2e4b;
    margin-bottom: 15px;
    p {
      line-height: 30px;
      span {
        margin-right: 20px;
      }
    }
  }
}
.upload-btn {
  width: 5rem;
  .el-upload {
    width: 5rem;
    height: auto !important;
    border: none !important;
  }
}
.tsColor1 {
  color: #37bf77;
}
